.connectButtons {
  &__btnConnect {
    background-color: #286fd1;

    &:hover {
      background-color: #286fd1 !important;
    }
  }
}

$assetsPath :"../../../../public/css_sprites_integrations.png";

.bg-QuickbooksDesktop {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -426px -390px;
}
