.custom-submit-btn {
  margin-left: 5px;
  background-color: #286fd1;
}

.custom-cancel-btn {
  width: 120px;
}

.custom-submit-btn:hover,
.custom-submit-btn:focus {
  background-color: #286fd1;
  color: white;
}

.custom-submit-btn:hover,
.custom-submit-btn:hover {
  background-color: #286fd1 !important;
  color: white;
}

.ant-upload-drag-icon {
  font-size: 40px;
  color: #286fd1;
}
