.search-filter {
  &__wrapper {
    padding: 2rem 2rem 2rem 2rem;
  }
  &__search {
    width: 100% !important;
  }
  &__select {
    width: 100% !important;
  }
  &__reset {
    height: 39px !important;
    font-size: larger;
    background-color: #286fd1;
  }

  &__reset:hover {
    background-color: #286fd1 !important;
  }
}
