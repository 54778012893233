.connectedTableButtons {
  display: flex;
  align-items: center;
  justify-content: center;

  &--btn {
    margin: 0px 0.5rem;
    border: none;
    // font-size: 1.8rem;
    padding: 0.5rem 0.5rem;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
    max-height: 43px;
  }

  &--disconnectButton {
    background: #999999ff;
    width: fit-content;
    color: #fff;

    border-radius: 6px !important;
    border: 1px solid #999999ff;
    cursor: pointer;

    &:hover {
      background: #999999ff;
    }

    img {
      position: relative !important;
      bottom: 9px !important;
    }
  }

  &--configurationButton {
    background: #286fd1;
    width: fit-content;
    color: #fff;

    border-radius: 6px !important;
    border: 1px solid #286fd1;
    cursor: pointer;

    &:hover {
      background: #286fd1;
    }

    img {
      position: relative !important;
      bottom: 9px !important;
    }
  }

}

$assetsPath :"../../../../../../public/css_sprites_integrations.png";

.bg-Odoo {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -10px -390px;
}

.bg-NetSuite {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -218px -390px;
}

.bg-QuickbooksOnline {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -10px -466px;
}

.bg-QuickbooksDesktop {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -426px -390px;
}

.bg-SageIntacct {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -218px -466px;
}

.bg-SAP {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -426px -466px;
}

.bg-Shopify {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -10px -542px;
}

.bg-ShopWare {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -218px -542px;
}

.bg-Walmart {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -10px -86px;
}

.bg-Stripe {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -10px -10px;
}

.bg-ZohoBook {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -10px -162px;
}

.bg-WooCommerce {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -218px -10px;
}

.bg-AuthorizeNet {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -10px -238px;
}

.bg-BigCommerce {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -218px -238px;
}

.bg-BrightPearl {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -10px -314px;
}

.bg-ClearBook {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -218px -314px;
}

.bg-Xero {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -218px -86px;
}

.bg-MsDynamic {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -426px -10px;
}

.bg-Ebay {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -426px -86px;
}

.bg-Acumatica {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -218px -162px;
}

.bg-FreshBooks {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -634px -162px;
}

.bg-Magento {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -426px -238px;
}

// .bg-myob_removebg_preview {
//   width: 188px;
//   height: 56px;
//   background: url(#{$assetsPath}) -634px -238px;
// }

.bg-BusinessCentral {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -426px -314px;
}


.bg-Salesforce {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -426px -542px;
}

.bg-ZohoCRM {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -634px -10px;
}

.bg-WaveAccount {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -634px -86px;
}

.bg-AmazonMarketPlace {
  width: 188px;
  height: 56px;
  background: url(#{$assetsPath}) -634px -314px;
}