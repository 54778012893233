.settings-layout { 
  max-height :calc(100vh - 28rem) !important;
	// height: 100%;
  &__wrapper {
    display: flex;
    height: 100%;
  }
  &__body {
    position: relative;
    width: 100%;
    background: #f3f5f7;
    padding: 0rem 3rem 0rem 3rem;
  }
}
