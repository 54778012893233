.integration-table {
	&__action {
		display: flex;
		gap: 20px;
		&--button{
			border-radius: 0px ;
		}
	}

}
