.ant-form-item {
  margin-bottom: 0px !important;
}

.ant-input,
.ant-input:focus,
.ant-input:active,
.ant-input:hover {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.text-area-error {
  .ant-form-item-explain-error {
    margin-top: -4px;
  }
}

.custom-single-input {
  margin-bottom: 3rem !important;
}

.ant-form-item-label {
  padding-bottom: 2px !important;
}

.custom-single-input-number {
  width: 100%;
}
