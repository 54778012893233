@keyframes SlideIn {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0%);
	}
}

@keyframes SlideOut {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(100%);
	}
}

@keyframes SlideTop {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes SlideBottom {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(100%);
	}
}
