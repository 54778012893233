.filter-container {
  .ant-menu-light .ant-menu-item-selected,
  .ant-menu-light > .ant-menu .ant-menu-item-selected {
    color: #286fd1 !important; /* Change text color to red */
  }
}

.register-filter {
  .ant-select {
    width: 22rem;
    height: 4rem;
  }
}
