.export-data {
	&__wrapper {
		display: flex;
		gap: 1rem;
		align-items: center;
		&-svg {
			font-size: 2.2rem;
			margin-right: 0rem;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
