.sticky-row {
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 3;
}

.table-globall {
  border-radius: 0% !important;
}


.column-group-items {
   background-color:rgb(217, 218, 219) !important;
}

.item {
  background-color: rgb(245, 239, 231) !important;
}

.master-carton {
  background-color: rgb(231,239,248) !important;
}

.pallet {
  background-color: rgb(228, 243, 228) !important;
}
