.integration-menu-item {
  background-color: #fff;

  .ant-menu-item {
    padding: 10px !important;
    margin: 0 !important;
    width: 100%;
    height: 50px;

    gap: 8px;
    .ant-menu-title-content {
      font-size: 1.6rem !important;
      color: #000;
      font-weight: 600;
    }
  }
  .ant-menu-item-selected {
    background-color: #f3f8ff !important;
    margin: 0;
    border-left: 2px #0074ff solid;
    border-radius: unset;
    width: 100%;
  }
  .ant-menu-item-active {
    background-color: #f3f8ff !important;
    border-radius: unset;
  }
}
.ant-layout-sider-children {
  height: 100%;
}
.integration-sidebar {
  height: calc(100vh - 24rem) !important;
}
