.side-drawer-form {
  &__inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // column-gap: 3rem;
    // row-gap: 3rem;
    & > div {
      padding-left: 3.636rem;
      padding-right: 3.636rem;
    }
  }
  &__role {
    font-weight: 600 !important;

    &--label {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      // gap: 0.8rem;

      font-size: 1.6rem;
      font-weight: 600 !important;
      color: #27272e;
    }
    &--select {
      font-size: 1.6rem;
      font-weight: 600;
      color: #27272e;
      width: 100%;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    gap: 2rem;
    // margin-top: 38rem;
    padding-left: 3.336rem;
    padding-right: 3.336rem;
    margin-top: 20px;
    &--btn {
      border: none;
      font-size: 1.8rem;
      padding: 1.8rem 4rem;
      border-radius: 5000px;
      cursor: pointer;
      font-weight: 600;
    }
    &--save {
      display: flex;
      padding: 1.3rem 2.8rem;
      max-height: 41.8px;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: 1px solid #000;
      box-shadow: none;
      font-size: 1.6rem;
      color: #000;
      font-weight: 600;
      border-radius: 6px !important;
      cursor: pointer;
      width: 13rem;
      // min-height: 5.2rem;
      &:hover {
        color: #fff !important;
        background: #286fd1 !important;
        border: 1px solid #286fd1;
      }
    }
    &--save-and-add {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #286fd1;
      font-size: 1.6rem;
      color: #fff;
      font-weight: 600;
      border-radius: 6px !important;
      border: 1px solid #286fd1;
      padding: 1.3rem 2.8rem;
      cursor: pointer;
      width: 18rem;
      max-height: 41.8px;
      &:hover {
        background: #286fd1 !important;
      }
    }
    &--edit {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #286fd1;
      font-size: 1.6rem;
      color: #fff;
      font-weight: 600;
      border-radius: 6px !important;
      border: 1px solid #286fd1;
      padding: 1.3rem 2.8rem;
      cursor: pointer;
      width: 13rem;
      max-height: 41.8px;
      &:hover {
        background: #286fd1 !important;
      }
    }
    &--cancel {
      display: flex;
      padding: 1.3rem 2.8rem;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: 1px solid #000;
      box-shadow: none;
      font-size: 1.6rem;
      color: #000;
      font-weight: 600;
      border-radius: 6px !important;
      cursor: pointer;
      width: 13rem;
      max-height: 41.8px;
      &:hover {
        background: #fff !important;
        color: #27272e !important;
      }
    }
  }
}
.customPhoneInput {
  height: 40px !important;
  width: 326.97px !important;
}

.required-asterisk {
  color: #ff4d4f;
  margin-left: 4px;
  font-size: 14px;
  font-family: SimSun, sans-serif;
}

.custom-label {
  display: inline-flex;
  align-items: center;
}
