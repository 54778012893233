.input-icon {
	margin-bottom: 2.5rem;
	&__title {
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		gap: 0.8rem;
		&--svg {
			display: flex;
			align-items: center;
		}
		&--label {
			font-size: 1.6rem !important;
			font-weight: 600;
			color: #27272E;
		}
	}
	&__form {
		&--input {
			border: 1px solid #d2d4d8 !important;
			&:active,
			&:focus,
			&:hover {
				box-shadow: none !important;
				border: 1px solid #d2d4d8 !important;
			}
		}
	}
}
.phoneError{
	color: #ff4d4f !important;
}