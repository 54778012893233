.shipment-table {
  &__wrapper {
    background-color: white;
    //  padding: 0 2rem 2rem 2rem;
    border-radius: 10px;
    overflow: auto;
  }

  :global {
    .ant-table {
      .ant-table-thead > tr > th {
        background-color: white;
        font-weight: 600;
        padding: 16px;
      }

      .ant-table-tbody > tr > td {
        padding: 16px;
      }
    }

    // .table-global {
    //   margin: 0 2rem;
    // }
    
  }
}
