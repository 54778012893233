*,
::after,
::before {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Lexend", sans-serif !important;
}

html {
  font-size: 10px;
}

body:has(.stop-scroll-x) {
  overflow: hidden;
}

body:has(.stop-click) {
  pointer-events: none;
}

body {
  font-family: "Lexend", sans-serif !important;
}

/* Removed scrollbar temporary */
::-webkit-scrollbar {
  width: 0px;
}

/* Global css */
.btn-blue {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #286fd1;
  font-size: 1.6rem;
  color: #fff;
  font-weight: 600;
  border-radius: 6px !important;
  gap: 8px;
  padding: 1.3rem 2.8rem;
  border: none;
  cursor: pointer;
}

.btn-disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #a6a7a8;
  font-size: 1.6rem;
  color: #fff;
  font-weight: 600;
  border-radius: 6px !important;
  gap: 8px;
  padding: 1.3rem 2.8rem;
  border: none;
  cursor: not-allowed;
}

.ant-table-column-title {
  flex: 0 0 auto !important;
}

.ant-table-column-sorters {
  justify-content: flex-start !important;
  gap: 10px;
}

.btn-blue:hover {
  color: #fff !important;
}

/* .ant-modal-content {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
} */

.ant-input-affix-wrapper {
  border: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper:focus {
  outline: none !important;
  box-shadow: none !important;
}

.ant-switch-checked {
  background: #009049 !important;
}

.table-global thead tr th {
  font-weight: 600 !important;
  font-size: 1.6rem;
}
.table-global tbody tr {
  font-size: 1.6rem;
}

.ant-table-cell .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #286fd1;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #d4d7dc !important;
}

.ant-checkbox-checked,
.ant-checkbox-checked::after {
  border: none !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
  /* background-color: #009049 !important; */
  border-color: #d4d7dc !important;
  outline: none !important;
}
.ant-switch-handle::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  height: 100%;
  width: 100%;
  background-image: url("/public/assets/images/false.png");
  background-repeat: no-repeat;
  background-size: 11px;
  background-position: center center;
}

.ant-switch-checked .ant-switch-handle::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #009049;
  height: 100%;
  width: 100%;
  font-weight: bold;
  background-image: url("/public/assets/images/true.png");
  background-repeat: no-repeat;
  background-size: 11px 9px;
  background-position: center center;
}

.required-color {
  color: red;
}

.ant-upload-select:hover button {
  color: #999999 !important;
  border-color: #999999 !important; /* Optional: Change the border color on hover if needed */
  background-color: transparent !important; /* Optional: Change the background color on hover if needed */
}

.link {
  border-bottom: 1px solid #153fcd;
  color: #153fcd;
}

.link:hover {
  cursor: pointer;
}

.pointer:hover {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* End global */
@media (max-width: 1600px) {
  html {
    font-size: 9px;
  }
}

@font-face {
  font-family: "Lexend";
  src: url("/public/assets/fonts/Lexend-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Lexend";
  src: url("/public/assets/fonts/Lexend-Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Lexend";
  src: url("/public/assets/fonts/Lexend-SemiBold.woff") format("woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Lexend";
  src: url("/public/assets/fonts/Lexend-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}

.cursor-pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}

.bg-white {
  background-color: #fff !important;
}
.bg-white:hover {
  background-color: #fff !important;
}

.ant-table-cell::before,
.ant-table-cell::after {
  display: none;
}

.toast-container-div {
  width: 25px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toast-container-custom svg {
  min-width: min-content !important;
  width: 100% !important;
}

.pointer-event-none {
  pointer-events: none !important;
}
.ant-btn.ant-btn-circle {
  min-width: 96px !important;
}
.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 5px 20px;
}

.save-button {
  border: 1px solid #286fd1 !important;
  color: #fff !important;
  background-color: #286fd1 !important;
  min-width: 15rem !important;
  /* border-radius: 1.3rem; */
  min-height: 3.8rem;
  font-size: 1.5rem;
}

.save-button:hover {
  cursor: pointer;
}

.cancel-button {
  background: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
  min-width: 15rem !important;
  /* border-radius: 1.3rem; */
  min-height: 3.8rem;
  font-size: 1.5rem;
}

.cancel-button:hover {
  cursor: pointer;
}

.button {
  min-width: 14rem;
  min-height: 3rem;
  border: 1px solid #286fd1;
  border-radius: 0.8rem;
  color: #fff;
  background-color: #286fd1;
}

.button-icon {
  display: "flex";
  justify-content: center;
  align-items: center;
  gap: 15px;
  min-height: 3rem;
}

.error-border-input {
  border: 1px solid red !important;
}

.error-border-select > .ant-select-selector {
  border: 1px solid red !important;
}

.ant-select-selection-overflow {
  padding: 4px !important;
}

.ant-btn-icon > .anticon-close-circle {
  margin-top: 5px !important;
}

.ant-modal-title {
  font-size: 18px !important;
}

.custom-single-input.required-icon-right
  .ant-form-item-label
  > label.ant-form-item-required::before {
  display: none;
}

.ant-modal-content {
  padding-top: 2rem !important;
  padding-bottom: 3% !important;
}

.ant-tabs .ant-tabs-tab {
  padding: 0px !important;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 4px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 12px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: rgb(52, 71, 53); */
  background: #aaa9a9 !important;
  border-radius: 12px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa9a9 !important;
  border-radius: 12px !important;
}

.ant-table-wrapper .ant-table {
  scrollbar-color: unset !important;
}

.export-button:hover {
  border-color: #286fd1 !important;
  color: #286fd1 !important;
}

.ant-table-expanded-row > td {
  background: white !important;
}

.ant-table-row-expand-icon-cell {
  background: white !important;
}

.full-screen-modal {
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
}

.full-screen-modal .ant-modal-content {
  height: 100vh;
  overflow-y: auto;
  padding: 10px;
}

.full-screen-modal .ant-modal-body {
  /* height: 100%; */
  overflow: auto;
  padding: 10px;
}

.full-screen-modal .ant-modal-header {
  padding-left: 10px;
}

.dimensions-row{
  background-color: #faf8f8;
}

.shipping-table .ant-table-thead > tr > th {
  background-color:rgb(217, 218, 219);
  font-weight: 600 !important;
  font-size: 1.6rem;
}
.shipping-table tbody tr {
  font-size: 1.6rem;
}