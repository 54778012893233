.confirm-delete-model {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding-left: 34px;
  padding-right: 34px;

  &__title {
    font-size: 26px;
    letter-spacing: -0.13px;
    width: 400px;
    margin: 2.3rem auto 0 auto;
    text-align: center;

    &--delete {
      color: #e74032;
    }
  }
}
.confirm-delete-model__button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 3.2rem;
  &--btn {
    border: none;
    font-size: 1.8rem;
    // padding: 1.3rem 2.8rem;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
    height: 43px;
    
  }
  &--save {
    background: #e74032;
    width: 142px;
    color: #fff;

    border-radius: 6px !important;
    border: 1px solid #e74032;
    cursor: pointer;

    &:hover {
      background: #e74032;

    }
  }
  &--cancel {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    width: 142px;
  }
}
