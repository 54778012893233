.missing-parts-modal {
  :global(.ant-modal-content) {
    padding: 24px;
    border-radius: 8px;
    // max-height: 80vh;
    height: 550px;
    display: flex;
    flex-direction: column;
  }

  :global(.ant-modal-body) {
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.modal-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-container {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.modal-title {
  margin-bottom: 24px;
  color: #27272e;
  font-weight: 600;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.missing-parts-table {
  :global(.ant-table-thead > tr > th) {
    background-color: #f5f5f5;
    font-weight: 600;
    padding: 12px 16px;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  :global(.ant-table-thead > tr > th) {
    &:nth-child(1) {
      width: 15%;
      min-width: 120px;
    }
    &:nth-child(2) {
      width: 20%;
      min-width: 150px;
    }
    &:nth-child(3) {
      width: 35%;
      min-width: 250px;
    }
    &:nth-child(4) {
      width: 20%;
      min-width: 150px;
    }
    &:nth-child(5) {
      width: 10%;
      min-width: 80px;
      text-align: center;
    }
  }
}

.table-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close-button {
  height: 40px;
  padding: 0 24px;
  font-size: 16px;
  border-radius: 6px;
  font-weight: 500;
}
